import { getAppList, getRoutesMap } from '../../context/ClientConfigContext';
import baseRoutesMap from '../../routing/routesMap';

const buildRoutesMap = ({ clientConfig } = {}) => {
	const appList = getAppList(clientConfig);
	const resultRoutesMap = { ...baseRoutesMap };
	appList.forEach((app) => {
		const { routesMap } = app;
		if (typeof routesMap === 'function') {
			Object.assign(resultRoutesMap, routesMap(clientConfig));
		}
	});

	const appLevelRoutesMap = getRoutesMap(clientConfig);
	if (typeof appLevelRoutesMap === 'function') {
		Object.assign(resultRoutesMap, appLevelRoutesMap(clientConfig));
	}

	return resultRoutesMap;
};

export default buildRoutesMap;
