import { redirect, NOT_FOUND } from 'redux-first-router';
import ReactGA from 'react-ga4';
import marshalRequest, {
	shouldRedirect,
	isLoggedIn,
	errorRedirectAction,
} from './marshal';
import { actions as routerActions } from './store/Pages/actions';
import track from '../shared/libs/analytics';

const isAllowed = ({ action, user, company, deviseAuth, routesMap }) => {
	const { type } = action;
	return marshalRequest({
		actionType: type,
		user,
		routesMap,
		company,
		deviseAuth,
	});
};

const showNotFoundOrLogin = ({ dispatch, user, company }) => {
	if (isLoggedIn({ user, company })) return;

	const newAction = redirect(routerActions.goToUnAuthorised());
	dispatch(newAction);
};

const goToUnAuthorisedAction = ({ routesMap, action }) => {
	const { type } = action;
	const routeEntry = routesMap[type];
	if (routeEntry) {
		const { redirectAction } = routeEntry;
		if (redirectAction) return redirectAction();
	}

	return routerActions.goToUnAuthorised();
};

const performRedirect = (dispatch, redirectAction) => {
	if (redirectAction) {
		const newAction = redirect(redirectAction());
		dispatch(newAction);
		return true;
	}
	return false;
};

const testPossibleRedirect = (dispatch, type, routesMap) => {
	const redirectAction = shouldRedirect({ actionType: type, routesMap });
	return performRedirect(dispatch, redirectAction);
};

const performNotAllowed = (dispatch, type, routesMap, action) => {
	const customAction = errorRedirectAction({ actionType: type, routesMap });
	const newAction = customAction
		? redirect(customAction())
		: redirect(goToUnAuthorisedAction({ routesMap, action }));
	dispatch(newAction);
};

export const createRouterHooks = (cableConnection) => {
	const subscription = cableConnection.subscriptions.create({
		channel: 'Account::AppearanceChannel',
	});

	return {
		onBeforeChange: (dispatch, getState, actionWrapper) => {
			const {
				user,
				deviseAuth,
				company,
				location: { routesMap },
			} = getState();
			const { action } = actionWrapper;
			const { type } = action;

			if (type === NOT_FOUND) {
				showNotFoundOrLogin({ dispatch, user, company });
				return;
			}

			if (testPossibleRedirect(dispatch, type, routesMap)) return;

			const { success, redirectAction } = isAllowed({
				action,
				user,
				company,
				routesMap,
				deviseAuth,
			});
			if (success) return;

			if (redirectAction) {
				performRedirect(dispatch, redirectAction);
				return;
			}
			performNotAllowed(dispatch, type, routesMap, action);
		},
		onAfterChange: (dispatch, getState) => {
			const {
				location: { pathname },
				app: {
					serverParams: { reactBuildNumberCurrent, reactBuildNumberUpdate },
				},
			} = getState();

			ReactGA.send({ hitType: 'pageview', page: pathname });

			track.pageview({
				page_path: pathname,
			});
			subscription.perform('appear', { appearing_on: pathname });

			// Reload the page if the build versions do not match
			if (reactBuildNumberCurrent !== reactBuildNumberUpdate) {
				window.location.reload();
			}
		},
	};
};
