import { constants } from './actions';
import * as deviseMarshal from './marshal';
import * as routeMarshal from '../../../routing/marshal';
import { constants as routerConstants } from '../../../routing/store/Pages/actions';

export const SIGN_IN_FORM = 'SignInForm';
export const SIGN_IN_TOKEN_FORM = 'SignInTokenForm';
export const NEW_PASSWORD_FORM = 'NewPasswordForm';
export const UNLOCK_FORM = 'UnlockForm';
export const CHANGE_PASSWORD_FORM = 'ChangePasswordForm';
export const CONFIRM_INVITE_FORM = 'ConfirmInviteForm';
export const CONFIRM_INVITE_ERROR_FORM = 'ConfirmInviteErrorForm';
export const INVITE_FORM = 'InviteForm';
export const REGISTER_FORM = 'RegisterForm';
export const CONFIRM_REGISTRATION_FORM = 'ConfirmRegistrationForm';
export const UN_AUTHORISED_PAGE = 'UnAuthorisedPage';

const createRoutesMap = (componentPathMap) => ({
	[routerConstants.goToLogin]: {
		path: '/account/users/sign_in',
		component: componentPathMap[SIGN_IN_FORM],
		handlesNotifications: true,
	},
	[routerConstants.goToUnAuthorised]: {
		pageTitle: 'UnAuthorised',
		path: '/un_authorised',
		component: componentPathMap[UN_AUTHORISED_PAGE],
	},
	[constants.goToVerifyLoginToken]: {
		path: '/account/users/sign_in/token',
		component: componentPathMap[SIGN_IN_TOKEN_FORM],
		handlesNotifications: true,
		conditions: [deviseMarshal.hasTFARequestToken],
	},
	[constants.goToForgotPassword]: {
		path: '/account/users/password/new',
		component: componentPathMap[NEW_PASSWORD_FORM],
		handlesNotifications: true,
	},
	[constants.goToUnlockResendForm]: {
		path: '/account/users/unlock/new',
		component: componentPathMap[UNLOCK_FORM],
		handlesNotifications: true,
	},
	[constants.goToUnlockSignInForm]: {
		path: '/account/users/unlock/new.:id?',
		component: componentPathMap[SIGN_IN_FORM],
		handlesNotifications: true,
	},
	[constants.goToChangePasswordForm]: {
		path: '/account/users/password/edit',
		component: componentPathMap[CHANGE_PASSWORD_FORM],
		conditions: [deviseMarshal.hasResetToken],
		handlesNotifications: true,
	},
	[constants.goToUserAcceptInviteError]: {
		path: '/account/users/invitation/accept/error',
		component: componentPathMap[CONFIRM_INVITE_ERROR_FORM],
		handlesNotifications: true,
	},
	[constants.goToUserAcceptInvite]: {
		path: '/account/users/invitation/accept',
		component: componentPathMap[CONFIRM_INVITE_FORM],
		handlesNotifications: true,
		conditions: [deviseMarshal.hasInviteToken],
		errorRedirectAction: constants.goToUserAcceptInviteError,
	},
	[constants.goToUserConfirmRegistration]: {
		pageTitle: 'User Registration',
		path: '/account/users/registration/confirmation',
		component: componentPathMap[CONFIRM_REGISTRATION_FORM],
		handlesNotifications: true,
		conditions: [routeMarshal.isLoggedIn],
	},
	[constants.goToUserInvite]: {
		pageTitle: 'User Invite',
		path: '/account/users/invitation/new',
		component: componentPathMap[INVITE_FORM],
		conditions: [routeMarshal.isLoggedIn],
	},
	[constants.goToUserRegistration]: {
		pageTitle: 'User Registration',
		path: '/account/users/registration',
		component: componentPathMap[REGISTER_FORM],
		handlesNotifications: true,
	},
});

export default createRoutesMap;
