import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ActionCableContext from './ActionCableContext';
import { actions as appActions } from '../core/store/App/actions';
import useFileDownload from '../hooks/useFileDownload';

const CableNotificationsContext = createContext();

export const CableNotificationsProvider = ({ children }) => {
	const cableContext = useContext(ActionCableContext);
	const dispatch = useDispatch();
	const handleFileDownload = useFileDownload();
	const { setReactBuildNumber, setServerIsUpdating } = appActions;

	const handleBuildNumberUpdate = (payload) => {
		const { appBuildNumber } = payload;
		dispatch(
			setReactBuildNumber({
				buildNumber: appBuildNumber,
			})
		);
	};

	const handleServerUpdateReceived = (payload) => {
		const { serverUpdating } = payload;
		dispatch(setServerIsUpdating({ serverUpdating }));
	};

	const serverNotificationReceived = (data) => {
		const { type, payload } = data;

		// eslint-disable-next-line default-case
		switch (type) {
			case 'react_build_number':
				handleBuildNumberUpdate(payload);
				break;
			case 'server_update_progress':
				handleServerUpdateReceived(payload);
				break;
			case 'fileDownloadUpdate':
				handleFileDownload(payload);
				break;
		}
	};

	const subscriptions = [];
	if (cableContext) {
		const { cable } = cableContext;
		subscriptions.push(
			cable.subscriptions.create(
				{ channel: 'Account::NotificationsChannel' },
				{
					received: serverNotificationReceived,
				}
			)
		);
		subscriptions.push(
			cable.subscriptions.create(
				{ channel: 'Account::UserChannel' },
				{
					received: serverNotificationReceived,
				}
			)
		);
	}

	return (
		<CableNotificationsContext.Provider
			value={useMemo(() => ({ subscriptions }), [subscriptions])}
		>
			{children}
		</CableNotificationsContext.Provider>
	);
};

CableNotificationsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CableNotificationsContext;
