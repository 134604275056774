import { AUTH_TOKEN, AUTH_TOKEN_HEADER } from '../../shared/constants/network';

export default {
	authenticityToken() {
		const token = document.querySelector('meta[name="csrf-token"]');
		if (token && token instanceof window.HTMLMetaElement) {
			return token.content;
		}
		return null;
	},

	authenticityHeaders(otherHeaders = {}) {
		const token = this.authenticityToken();
		return {
			...otherHeaders,
			'X-CSRF-Token': token,
			'X-Requested-With': 'XMLHttpRequest',
		};
	},

	createAuthHeaders(addCRFToken = true) {
		const token = localStorage.getItem(AUTH_TOKEN);
		const authHeaders = {
			[AUTH_TOKEN_HEADER]: `Bearer ${token}` || '',
		};
		if (addCRFToken) return this.authenticityHeaders(authHeaders);

		return authHeaders;
	},
};
