import { i18n } from '@lingui/core';
import { en, de } from 'make-plural/plurals';

export const locales = {
	'en-ZA': 'English - South Africa',
	de: 'German',
};
export const defaultLocale = 'en-ZA';

i18n.loadLocaleData({
	'en-ZA': { plurals: en },
	de: { plurals: de },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale) {
	const { messages } = await import(`../../locale/${locale}/messages.mjs`);
	i18n.load(locale, messages);
	i18n.activate(locale);
}
