import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useApolloClient } from '@apollo/client';
import ClientConfigContext from './ClientConfigContext';
import ActionCableContext from './ActionCableContext';

const ApolloCableContext = createContext();

const getCompanyName = ({ company: { domain } }) => domain;

export const ApolloCableProvider = ({ children, channels }) => {
	const { cache } = useApolloClient();
	const { getApolloCacheHandler } = useContext(ClientConfigContext);
	const cableContext = useContext(ActionCableContext);
	const companyName = useSelector(getCompanyName);

	const updateUserField = ({ field, item }) => {
		const { idTuple } = item;
		if (!idTuple) return;

		const { id, actableId } = idTuple;
		cache.modify({
			id: cache.identify({
				__typename: field,
				id: id.toString(),
				actableId: actableId.toString(),
			}),
			fields: (fieldValue, { fieldName }) => {
				if (
					fieldName in item &&
					(fieldName !== 'id' || fieldName !== 'actableId')
				) {
					return item[fieldName];
				}
				return fieldValue;
			},
		});
	};

	// Identify objects by type and id. If no id, return.
	const handleItemUpdate = ({ field, item }) => {
		if (!item) return;

		const { id, idTuple } = item;
		if (idTuple) return updateUserField({ field, item });
		if (!id) return;

		cache.modify({
			id: cache.identify({
				__typename: field,
				id: id.toString(),
			}),
			fields: (fieldValue, { fieldName }) => {
				if (fieldName in item && fieldName !== 'id') {
					return item[fieldName];
				}
				return fieldValue;
			},
		});
	};

	const handlePlugins = (data) => {
		const handler = getApolloCacheHandler({ companyName });
		if (handler && typeof handler === 'function') {
			handler(cache, data);
		}
	};

	const serverUpdateReceived = (data) => {
		const { type, payload } = data;
		// eslint-disable-next-line default-case
		switch (type) {
			case 'apollo_cache_update_item':
				handleItemUpdate(payload);
				break;
		}
		handlePlugins(data);
	};

	let subscriptions = [];
	if (cableContext) {
		const { cable } = cableContext;
		subscriptions = _.map(channels, (channel) => {
			cable.subscriptions.create(
				{ channel },
				{
					received: serverUpdateReceived,
				}
			);
		});
	}

	return (
		<ApolloCableContext.Provider
			value={useMemo(() => ({ subscriptions }), [subscriptions])}
		>
			{children}
		</ApolloCableContext.Provider>
	);
};

ApolloCableProvider.propTypes = {
	children: PropTypes.node.isRequired,
	channels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ApolloCableContext;
