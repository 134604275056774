import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import Authenticity from '../lib/Authenticity';
import {
	AUTH_TOKEN,
	REFRESH_TOKEN_HEADER,
} from '../../shared/constants/network';

const createApolloCache = (props, appConfig) => {
	const { serverUrl } = props;

	const mainLink = new HttpLink({ uri: `${serverUrl}/graphql` });

	const authMiddleware = new ApolloLink((operation, forward) => {
		const authHeaders = Authenticity.createAuthHeaders();
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				...authHeaders,
			},
		}));

		return forward(operation);
	});

	const afterwareLink = new ApolloLink((operation, forward) =>
		forward(operation).map((response) => {
			const context = operation.getContext();
			const {
				response: { headers },
			} = context;

			if (headers) {
				const refreshToken = headers.get(REFRESH_TOKEN_HEADER);
				if (refreshToken) {
					localStorage.setItem(AUTH_TOKEN, refreshToken);
				}
			}

			return response;
		})
	);

	const trackingMiddleware = new ApolloLink((operation, forward) => {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				'recent-activity': localStorage.getItem('lastOnlineTime') || null,
			},
		}));

		return forward(operation);
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach((value) => {
				console.log(value);
				const { message, locations, path } = value;
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				);
			});
		if (networkError) console.log(`[Network error]: ${networkError}`);
	});

	const retryLink = new RetryLink();

	let cache = null;
	const { createApolloCache: createCache } = appConfig;

	if (createCache && typeof createCache === 'function') {
		cache = createCache(props);
	} else {
		cache = new InMemoryCache();
	}

	return {
		mainLink,
		authMiddleware,
		trackingMiddleware,
		errorLink,
		retryLink,
		afterwareLink,
		cache,
	};
};

export default (clientConfig, props) => {
	/*
  This is for specifying additional external URI's in any app.
  The URI can be referenced by passing clientName as part of the Apollo context.
  The default one should always be the `mainLink` which is the ruby server/
   */
	const {
		mainLink,
		authMiddleware,
		trackingMiddleware,
		errorLink,
		retryLink,
		afterwareLink,
		cache,
	} = createApolloCache(props, clientConfig);

	let httpLink = mainLink;
	if (clientConfig.externalAPIs) {
		for (const api of clientConfig.externalAPIs) {
			httpLink = ApolloLink.split(
				(operation) => operation.getContext().clientName === api.clientName,
				api.httpLink,
				httpLink
			);
		}
	}

	const link = ApolloLink.from([
		authMiddleware,
		trackingMiddleware,
		errorLink,
		retryLink,
		afterwareLink,
		httpLink,
	]);

	return new ApolloClient({
		link,
		cache,
		connectToDevTools: true,
	});
};
