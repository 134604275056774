import { createAction } from 'redux-actions';

export const constants = {
	goToVerifyLoginToken: 'pages.go_to_verify_login_token',
	goToForgotPassword: 'pages.go_to_forgot_password',
	goToUnlockResendForm: 'pages.go_to_unlock_resend_form',
	goToUnlockSignInForm: 'pages.go_to_unlock_sign_in_form',
	goToChangePasswordForm: 'pages.go_to_change_password_form',
	goToUserInvite: 'pages.go_to_user_invite',
	goToUserAcceptInvite: 'pages.go_to_user_accept_invite',
	goToUserAcceptInviteError: 'pages.go_to_user_accept_error',
	goToUserRegistration: 'pages.go_to_user_registration',
	goToUserConfirmRegistration: 'pages.go_to_user_confirm_registration',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
