import { saveAs } from 'file-saver';

const useFileDownload = () => (payload) => {
	const { field, item } = payload;
	if (field !== 'FileDownload') return;

	const { fileName, downloadLink } = item;
	saveAs(downloadLink, fileName);
};

export default useFileDownload;
