import { createSelector } from 'reselect';

export default createSelector(
	[(state) => state.location.type, (state) => state.location.payload],
	(type, { data1, data2 }) => {
		if (type === 'FETCH1') return data1;
		if (type === 'FETCH2') return data2;
		return false;
	}
);
