import { all, call } from 'redux-saga/effects';
import { getAppList } from '../../context/ClientConfigContext';
import controlSaga from '../controls/saga';

const buildRootSaga = ({ clientConfig } = {}) => {
	const appList = getAppList(clientConfig);
	const sagaList = [call(controlSaga)];
	appList.forEach((app) => {
		const { getSagas } = app;
		if (typeof getSagas === 'function') {
			const sagasToAdd = app.getSagas();
			sagasToAdd.forEach((saga) => {
				sagaList.push(call(saga));
			});
		}
	});

	const rootSaga = function* rootSaga() {
		yield all(sagaList);
	};

	return rootSaga;
};

export default buildRootSaga;
