import { NOT_FOUND } from 'redux-first-router';
import _ from 'lodash';
import { constants } from './actions';
import { constants as routingActions } from '../../../routing/store/Pages/actions';
import { and, isLoggedIn } from '../../../routing/marshal';
import createRoutesMap, {
	SIGN_IN_FORM,
	SIGN_IN_TOKEN_FORM,
	NEW_PASSWORD_FORM,
	UNLOCK_FORM,
	CHANGE_PASSWORD_FORM,
	CONFIRM_INVITE_FORM,
	CONFIRM_INVITE_ERROR_FORM,
	INVITE_FORM,
	REGISTER_FORM,
	UN_AUTHORISED_PAGE,
} from '../../../devise/store/routes/routesMap';

const componentPathMap = {
	[SIGN_IN_FORM]: () => import('../../pages/SignIn'),
	[SIGN_IN_TOKEN_FORM]: () => import('../../pages/SignInTokenPage'),
	[NEW_PASSWORD_FORM]: () => import('../../pages/NewPassword'),
	[UNLOCK_FORM]: () => import('../../pages/Unlock'),
	[CHANGE_PASSWORD_FORM]: () => import('../../pages/ChangePassword'),
	[CONFIRM_INVITE_FORM]: () => import('../../pages/AcceptInvitation'),
	[CONFIRM_INVITE_ERROR_FORM]: () => import('../../pages/AcceptInvitation'),
	[INVITE_FORM]: () => import('../../pages/Error401'),
	[REGISTER_FORM]: () => import('../../pages/Error401'),
	[UN_AUTHORISED_PAGE]: () => import('../../pages/Error401'),
};

const createCoreRoutesMap = (appConfig) => {
	const { userProfileComponent, routeConditions } = appConfig;
	const profileComp =
		userProfileComponent || (() => import('../../pages/CurrentUserProfile'));

	const deviseRoutesMap = createRoutesMap(componentPathMap);

	const notFoundEntry = {
		pageTitle: '404',
		component: () => import('../../pages/Error404'),
	};

	const actionConditions = ({ toAdd = [], route }) => {
		let conditions = [];
		if (_.isFunction(routeConditions)) {
			conditions = routeConditions({ route });
		}
		return { condition: and([...toAdd, ...conditions]) };
	};

	const authenticatedAction = ({ toAdd = [], route }) => {
		const loggedIn = [isLoggedIn, ...toAdd];
		return actionConditions({ toAdd: loggedIn, route });
	};

	return {
		...deviseRoutesMap,
		[routingActions.goToError401]: {
			path: '/errors/error-401',
			component: () => import('../../pages/Error401'),
		},
		[routingActions.goToTemporaryAccessRequired]: {
			path: '/errors/temp-access-required',
			component: () => import('../../pages/TempAccessRequired'),
		},
		[routingActions.goToError404]: {
			...notFoundEntry,
			path: '/errors/error-404',
		},
		[routingActions.goToError410]: {
			path: '/errors/error-410',
			component: () => import('../../pages/Error410'),
		},
		[routingActions.goToError500]: {
			path: '/errors/error-500',
			component: () => import('../../pages/Error500'),
		},
		[NOT_FOUND]: { ...notFoundEntry },
		[constants.goToUserProfile]: {
			component: profileComp,
			...authenticatedAction({ route: constants.goToUserProfile }),
			path: '/account/users/settings/:page',
		},
		[constants.goToSystemUsers]: {
			component: () => import('../../pages/Users'),
			...authenticatedAction({ route: constants.goToSystemUsers }),
			path: '/system/users',
		},
		[constants.goToSystemUser]: {
			component: () => import('../../pages/User'),
			...authenticatedAction({ route: constants.goToSystemUser }),
			path: '/system/users/:id&:actableId',
		},
		[constants.goToCompanySettings]: {
			component: () => import('../../pages/Settings'),
			...authenticatedAction({ route: constants.goToCompanySettings }),
			path: '/system/company/settings',
		},
	};
};

export default createCoreRoutesMap;
