import { createAction } from 'redux-actions';

export const constants = {
	setLoginOTP: 'device.auth.set_login_otp',
	clearLoginOTP: 'device.auth.clear_login_otp',
	clearAuthData: 'device.auth.clear_auth_data',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
