import { handleActions } from 'redux-actions';
import { constants } from './actions';

export const initialState = {
	name: null,
};

const handleSetCompanyName = (state, { payload }) => ({
	...state,
	name: payload,
});

export default handleActions(
	{
		[constants.setCompanyName]: handleSetCompanyName,
	},
	initialState
);
