import { all, takeEvery, put, select, getContext } from 'redux-saga/effects';
import { constants as deviseActionConst } from '../../devise/store/notification/actions';
import {
	actions as userActions,
	constants as userActionConst,
} from '../../core/store/User/actions';
import { getClientConfig } from '../lib/appsRegistry';
import { AUTH_TOKEN } from '../../shared/constants/network';

const getCompany = ({ company }) => company;

const getUser = ({ user }) => user;

function* getAfterSignInAction() {
	const company = yield select(getCompany);
	const apolloClient = yield getContext('apolloClient');
	const { domain } = company;
	const appConfig = yield getClientConfig({ domain });
	const user = yield select(getUser);
	const { afterSignInAction } = appConfig;
	return yield afterSignInAction({ user, apolloClient });
}

function* handleSetLogin({ payload }) {
	const { user, authToken } = payload;
	localStorage.setItem(AUTH_TOKEN, authToken || '');
	yield put(userActions.setUserProfile(user));
	const afterSignInAction = yield* getAfterSignInAction();
	yield put(afterSignInAction());
}

function* handleChangeUser({ payload }) {
	const afterSignInAction = yield* getAfterSignInAction();
	yield put(afterSignInAction());
	yield put(userActions.setUserProfile(payload));
}

function* watchLoginSucceed() {
	yield takeEvery(deviseActionConst.loginSucceeded, handleSetLogin);
}

function* watchUpdateSucceeded() {
	yield takeEvery(deviseActionConst.updateSucceeded, handleSetLogin);
}

function* watchChangeUser() {
	yield takeEvery(userActionConst.changeUser, handleChangeUser);
}

const controlSaga = function* saga() {
	yield all([watchLoginSucceed(), watchUpdateSucceeded(), watchChangeUser()]);
};

export default controlSaga;
