import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import NProgress from 'nprogress';
import { Box, LinearProgress } from '@mui/material';

const PREFIX = 'LoadingScreen';

const classes = {
	root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		minHeight: '100%',
		padding: theme.spacing(3),
	},
}));

const LoadingScreen = () => {
	useEffect(() => {
		NProgress.start();

		return () => {
			NProgress.done();
		};
	}, []);

	return (
		<Root className={classes.root}>
			<Box width={400}>
				<LinearProgress />
			</Box>
		</Root>
	);
};

export default LoadingScreen;
