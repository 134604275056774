import { createAction } from 'redux-actions';

export const constants = {
	goToUserProfile: 'pages.core.go_to_user_profile',
	goToSystemUser: 'pages.core.go_to_system_user',
	goToSystemUsers: 'pages.core.go_to_system_users',
	goToCompanySettings: 'pages.core.go_to_company_settings',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
