import { handleActions } from 'redux-actions';
import { constants } from './actions';

export const initialState = {
	userProfile: null,
};

const handleSetUserProfile = (state, { payload }) => ({
	...state,
	userProfile: { ...payload },
});

const handleSetUserMobile = (state, { payload }) => {
	const { userProfile } = state;
	return {
		...state,
		userProfile: { ...userProfile, mobile: payload },
	};
};

const handleSetTemporaryUser = (state, { payload }) => ({
	...state,
	temporaryAccess: { ...payload },
});

const handleClearUser = () => initialState;

export default handleActions(
	{
		[constants.setUserProfile]: handleSetUserProfile,
		[constants.setUserMobile]: handleSetUserMobile,
		[constants.clearUser]: handleClearUser,
		[constants.setTemporaryUser]: handleSetTemporaryUser,
	},
	initialState
);
