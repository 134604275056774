import React, { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from '../app/setup/themeConf/constants';
import { storeSettings } from '../shared/libs/settings';

const SettingsContext = createContext();

const defaultSettings = {
	direction: 'ltr',
	responsiveFontSizes: true,
	theme: THEMES.LIGHT,
};

export function SettingsProvider({ settings, children }) {
	const [currentSettings, setCurrentSettings] = useState({
		...defaultSettings,
		...settings,
	});

	const handleSaveSettings = (updatedSettings = {}) => {
		const mergedSettings = _.merge({}, currentSettings, updatedSettings);

		setCurrentSettings(mergedSettings);
		storeSettings(mergedSettings);
	};

	useEffect(() => {
		document.dir = currentSettings.direction;
	}, [currentSettings]);

	return (
		<SettingsContext.Provider
			value={useMemo(
				() => ({
					settings: currentSettings,
					saveSettings: handleSaveSettings,
				}),
				[currentSettings, handleSaveSettings]
			)}
		>
			{children}
		</SettingsContext.Provider>
	);
}

SettingsProvider.defaultProps = {
	settings: null,
};

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired,
	settings: PropTypes.objectOf(PropTypes.any),
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
