import _ from 'lodash';

export const initialState = {
	previousRoute: null,
	currentRoute: null,
	backActionCookies: {},
};

const extractBackActionCookie = (state, action) => {
	const { backActionCookies } = state;
	const newData = _.get(action, 'payload.backActionCookie', {});
	return {
		...backActionCookies,
		...newData,
	};
};

const handleRedirect = (state, action) => {
	const current = _.get(action, 'meta.location.redirect');
	if (!current) {
		return state;
	}
	return {
		...initialState,
		previousRoute: state.currentRoute,
		currentRoute: current,
		backActionCookies: extractBackActionCookie(state, action),
	};
};

const handleRouteChanged = (state, action) => {
	const current = _.get(action, 'meta.location.current');
	if (!current) {
		return handleRedirect(state, action);
	}
	// Check if we actually moved from the current location
	const routeType = state.currentRoute ? state.currentRoute.pathname : null;
	if (routeType === current.pathname) {
		return state;
	}
	return {
		...initialState,
		previousRoute: state.currentRoute,
		currentRoute: current,
		backActionCookies: extractBackActionCookie(state, action),
	};
};

const pagesReducer = (state = initialState, action) => {
	if (action.type.startsWith('pages.')) {
		return handleRouteChanged(state, action);
	}
	return state;
};

export default pagesReducer;
