import { softShadows } from './shadows';
import { THEMES } from './constants';

const themeConfigs = [
	{
		name: THEMES.LIGHT,
		palette: {
			mode: 'light',
			mainContentPainBackground: '#f4f6f8',
		},
		shadows: softShadows,
	},
	{
		name: THEMES.ONE_DARK,
		palette: {
			mode: 'dark',
			mainContentPainBackground: '#1c2025',
		},
		components: {
			// Name of the component
			MuiPaper: {
				styleOverrides: {
					root: {
						// Some CSS
						backgroundColor: '#282c34',
					},
				},
			},
		},
	},
];

export default themeConfigs;
