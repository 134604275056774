export const getClientConfig = async ({ domain } = {}) => {
	try {
		const result = await import(`../../apps/${domain}/config.jsx`);
		const { default: config } = result;
		return config;
	} catch (err) {
		console.log(err);
		return null;
	}
};
