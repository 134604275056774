import { createAction } from 'redux-actions';

export const constants = {
	setCompanyName: 'company.set_company_name',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
