import pageRouteReducer from './store/Pages/reducer';

export const APP_NAME = 'routing';

export const reducersMap = {
	pages: pageRouteReducer,
};

export default {
	name: APP_NAME,
	reducers: () => reducersMap,
};
