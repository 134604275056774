import axios from 'axios';
import Authenticity from '../../app/lib/Authenticity';

export const sendError = ({ serverUrl, error, componentStack, state }) => {
	const authHeaders = Authenticity.createAuthHeaders();
	let cleanedState;
	try {
		cleanedState = JSON.stringify(state);
	} catch (e) {
		console.log(e);
	}

	const data = {
		error: error.toString(),
		componentStack: componentStack.toString(),
		state: cleanedState,
	};
	axios
		.post(`${serverUrl}/errorlog`, data, { headers: authHeaders })
		.catch((error) => {
			console.error('Failed to log the error:', error);
		});
};
