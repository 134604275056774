import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {
	Box,
	Container,
	Typography,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import image500 from '../assets/img/error_500.svg';
import { ErrorImg } from './styled/ErrorImage';

export const Error500Container = ({ onButtonClick }) => {
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

	const displayButton = () => (
		<Box mt={6} display="flex" justifyContent="center">
			<Button color="secondary" onClick={onButtonClick} variant="outlined">
				<Trans>Let's try again</Trans>
			</Button>
		</Box>
	);

	return (
		<Container maxWidth="lg">
			<Typography
				align="center"
				variant={mobileDevice ? 'h4' : 'h1'}
				color="textPrimary"
			>
				<Trans>500: Ooops, something went terribly wrong!</Trans>
			</Typography>
			<Typography align="center" variant="subtitle2" color="textSecondary">
				<Trans>
					All errors are automatically reported and logged. Our team will fix
					this error asap!
				</Trans>
			</Typography>
			<Box mt={6} display="flex" justifyContent="center">
				<ErrorImg alt="Under development" src={image500} />
			</Box>
			{displayButton()}
		</Container>
	);
};

Error500Container.defaultProps = {
	onButtonClick: () => null,
};

Error500Container.propTypes = {
	onButtonClick: PropTypes.func,
};

export default Error500Container;
