const routesMap = {};

export const updateRouteEntries = (appList) => {
	appList.forEach((app) => {
		if (typeof app.getRoutesMap === 'function') {
			Object.assign(routesMap, app.getRoutesMap());
		}
	});
};

export default routesMap;
