import createStore from '../../shared/store';
import { buildReducerEntries, buildInitialState } from './reducers';
import buildRoutesMap from './routes';
import buildRootSaga from './sagas';

export default (props = {}) => {
	const { clientConfig, cableConnection, apolloClient } = props;

	const initialState = buildInitialState(props);

	const reducers = buildReducerEntries({ clientConfig });
	const routesMap = buildRoutesMap({ clientConfig });
	const rootSaga = buildRootSaga({ clientConfig });

	return createStore({
		cableConnection,
		apolloClient,
		initialState,
		reducers,
		routesMap,
		rootSaga,
	});
};
