import coreRouteMap from './store/Routes/routesMap';
import userReducer from './store/User/reducer';
import companyReducer from './store/Company/reducer';
import appReducer from './store/App/reducer';

export const APP_NAME = 'core';

export const reducersMap = {
	user: userReducer,
	company: companyReducer,
	app: appReducer,
};

export default {
	name: APP_NAME,
	reducers: () => reducersMap,
	routesMap: coreRouteMap,
};
