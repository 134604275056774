import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import coreConfig from '../core/config';
import routingConfig from '../routing/config';
import deviseConfig from '../devise/config';

const ClientConfigContext = createContext();

const getConfigField = (clientConfig, fieldName) =>
	clientConfig ? clientConfig[fieldName] : null;

const appListContainsApp = (list, appName) =>
	list.find((app) => {
		const { name } = app;
		return name === appName;
	});

const ensureUniqueAppList = (list) => {
	const result = [];
	list.forEach((app) => {
		const { name } = app;
		if (!appListContainsApp(result, name)) {
			result.push(app);
		}
	});
	return result;
};

export const getAppList = (clientConfig) => {
	const list = getConfigField(clientConfig, 'appList');
	let result = [coreConfig, routingConfig, deviseConfig];
	if (list) {
		result = result.concat(list);
	}
	return ensureUniqueAppList(result);
};

export const getCompanyTheme = (clientConfig) => {
	const theme = getConfigField(clientConfig, 'theme');
	return theme || {};
};

export const getApolloCacheHandler = (clientConfig) =>
	getConfigField(clientConfig, 'apolloCacheHandler');

export const getRoutesMap = (clientConfig) =>
	getConfigField(clientConfig, 'routesMap');

export const getSettingsList = (clientConfig) => {
	const appList = getAppList(clientConfig);
	let result = [];
	appList.forEach((app) => {
		const { settingsList } = app;
		if (typeof settingsList === 'function') {
			result = [...result, ...settingsList()];
		}
	});
	return result;
};

export const ClientConfigProvider = ({ clientConfig, children }) => (
	<ClientConfigContext.Provider
		value={useMemo(
			() => ({
				clientConfig,
				getAppList: () => getAppList(clientConfig),
				getCompanyTheme: () => getCompanyTheme(clientConfig),
				getApolloCacheHandler: () => getApolloCacheHandler(clientConfig),
				getSettingsList: () => getSettingsList(clientConfig),
			}),
			[clientConfig]
		)}
	>
		{children}
	</ClientConfigContext.Provider>
);

ClientConfigProvider.defaultProps = {
	clientConfig: null,
};

ClientConfigProvider.propTypes = {
	children: PropTypes.node.isRequired,
	clientConfig: PropTypes.objectOf(PropTypes.any),
};

export default ClientConfigContext;
