import React, { createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ActionCableContext = createContext();

export const ActionCableProvider = ({ children, consumer }) => {
	const profile = useSelector(({ user: { userProfile } }) => userProfile);
	const providerValue = useMemo(() => ({ cable: consumer }), [consumer]);
	if (!profile) return children;

	return (
		<ActionCableContext.Provider value={providerValue}>
			{children}
		</ActionCableContext.Provider>
	);
};

ActionCableProvider.displayName = 'ActionCableProvider';

ActionCableProvider.propTypes = {
	children: PropTypes.node.isRequired,
	consumer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const ActionCableConsumer = ActionCableContext.Consumer;

export default ActionCableContext;
