import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import $ from 'jquery';

const ReactComponentRenderer = (createComponent) => {
	const renderComponent = async () => {
		const dataContainer = $('#react-container');
		const root = createRoot(document.getElementById('react-container'));
		const myProps = dataContainer.data();
		const MainAppComponent = await createComponent(myProps);
		root.render(MainAppComponent);
	};

	const checkDocumentState = () => {
		if (document.readyState === 'complete') {
			renderComponent();
		} else {
			document.addEventListener('DOMContentLoaded', () => {
				renderComponent();
			});
		}
	};

	return checkDocumentState();
};

export default ReactComponentRenderer;
