import React, { createContext, useState, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const SnackbarContext = createContext();

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export const SnackbarProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [stackDetails, setStackDetails] = useState({
		message: '',
		severity: 'success',
	});

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') return;

		setOpen(false);
	};

	const showSnackbar = (message, { severity }) => {
		setStackDetails({ message, severity: severity || 'success' });
		setOpen(true);
	};

	return (
		<SnackbarContext.Provider
			value={useMemo(() => ({ showSnackbar }), [showSnackbar])}
		>
			<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity={stackDetails.severity}
					sx={{ width: '100%' }}
				>
					{stackDetails.message}
				</Alert>
			</Snackbar>
			{children}
		</SnackbarContext.Provider>
	);
};

SnackbarProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SnackbarContext;
