import _ from 'lodash';
import moment from 'moment';
import { actions as routeActions } from './store/Pages/actions';

const routeFunction = (actionType, routesMap, name) => {
	if (!actionType || !routesMap) return null;

	const routeEntry = routesMap[actionType];
	if (!routeEntry) return null;

	const reference = routeEntry[name];
	if (!reference) return null;

	if (typeof reference === 'function') {
		return reference;
	}
	return null;
};

export const shouldRedirect = ({ actionType, routesMap } = {}) =>
	routeFunction(actionType, routesMap, 'redirect');

export const errorRedirectAction = ({ actionType, routesMap } = {}) =>
	routeFunction(actionType, routesMap, 'errorRedirectAction');

const testLogInCondition = ({ user, company }) => {
	if (!user) return false;

	const { userProfile } = user;
	if (!userProfile || !company) return false;

	const { id: userId } = userProfile;
	if (!userId) return false;

	const { companyIds } = userProfile;
	if (!companyIds) return false;

	const { id: companyId } = company;
	const found = companyIds.find(
		(element) => element.toString() === companyId.toString()
	);
	return !!found;
};

class NotLoggedInException extends Error {
	constructor() {
		super('Not Logged In');
	}
}

// If a user object is present, the user must have an id and also
// a company id to describe the domain.
export const isLoggedIn = ({ user, company }) => {
	const test = testLogInCondition({ user, company });
	if (!test) throw new NotLoggedInException();

	return true;
};

// Test if the client has a temporary access token.
export const hasTemporaryAccess = (store) => {
	const expires = _.get(store, 'user.temporaryAccess.accessExpiresAt');
	if (!expires) return false;

	return moment().isSameOrBefore(moment(expires));
};

const validateOr = (params) => {
	if (!params) return false;

	const { conditions, ...rest } = params;
	if (conditions) {
		return conditions.some((condition) => condition({ ...rest }));
	}
	return false;
};

const validateAnd = (params) => {
	if (!params) return false;

	const { conditions, ...rest } = params;
	if (conditions) {
		return conditions.every((condition) => condition({ ...rest }));
	}
	return false;
};

export const or = (conditions) => (params) =>
	validateOr({ conditions, ...params });

export const and = (conditions) => (params) =>
	validateAnd({ conditions, ...params });

// We start with most secure to a point where a function allows
// the request.
export default ({ actionType, user, company, routesMap, deviseAuth } = {}) => {
	if (!actionType || !routesMap) return { success: false };

	const routeEntry = routesMap[actionType];
	if (!routeEntry) return { success: false };

	try {
		const { condition } = routeEntry;
		let result = true;
		if (condition) {
			result = condition({
				user,
				deviseAuth,
				company,
				routeEntry,
			});
		}
		return { success: result };
	} catch (e) {
		const isNotLoggedIn = e instanceof NotLoggedInException;
		if (isNotLoggedIn)
			return { success: false, redirectAction: routeActions.goToLogin };

		console.log(e);
		return { success: false };
	}
};
