import { createAction } from 'redux-actions';

export const constants = {
	loginSucceeded: 'devise_notifications.login_succeeded',
	logout: 'devise_notifications.logout',
	updateSucceeded: 'devise_notifications.update_succeeded',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
