import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Minimal = ({ children, sx }) => (
	<Box
		sx={[
			{
				display: 'flex',
				height: '100%',
				overflow: 'hidden',
				width: '100%',
			},
			...(Array.isArray(sx) ? sx : [sx]),
		]}
	>
		<Box
			sx={{
				flex: '1 1 auto',
				height: '100%',
				overflow: 'auto',
			}}
		>
			{children}
		</Box>
	</Box>
);

Minimal.defaultProps = {
	sx: [],
};

Minimal.propTypes = {
	children: PropTypes.node.isRequired,
	sx: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
		),
		PropTypes.func,
		PropTypes.object,
	]),
};

export default Minimal;
