import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as ActiveStorage from '@rails/activestorage';
import renderComponent from '../app/lib/componentRenderer';
import RoutedApp from '../app/components/RoutedApp';

ActiveStorage.start();

enableES5();

renderComponent(RoutedApp);
