import { handleActions } from 'redux-actions';
import { constants } from './actions';
import { constants as notificationActions } from '../notification/actions';

export const initialState = {
	otp: null,
};

const handleSetLoginOTP = (state, { payload }) => ({
	...state,
	otp: payload,
});

const handleClearLoginOTP = (state) => ({
	...state,
	otp: null,
});

const handleClearAuthData = () => initialState;

export default handleActions(
	{
		[constants.setLoginOTP]: handleSetLoginOTP,
		[constants.clearLoginOTP]: handleClearLoginOTP,
		[notificationActions.loginSucceeded]: handleClearAuthData,
		[constants.clearAuthData]: handleClearAuthData,
	},
	initialState
);
