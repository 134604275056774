import _ from 'lodash';

export const hasInviteToken = ({ deviseAuth }) => {
	if (!deviseAuth) return false;

	const { invitationToken } = deviseAuth;
	return invitationToken !== null;
};

export const hasResetToken = ({ deviseAuth }) => {
	if (!deviseAuth) return false;

	const { resetPasswordToken } = deviseAuth;
	return resetPasswordToken !== null;
};

export const hasTFARequestToken = ({ deviseAuth }) => {
	const token = _.get(deviseAuth, 'otp.tfaRequestToken', null);
	return token !== null;
};
