import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Error500 from './Error500';
import MinimalLayout from '../layouts/Minimal';

const StyledLayout = styled(MinimalLayout)(({ theme }) => ({
	backgroundColor: theme.palette.background.dark,
	minHeight: '100%',
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(3),
	paddingTop: 80,
	paddingBottom: 80,
}));

const ErrorBoundaryFallback = ({ resetErrorBoundary }) => {
	const handleClick = () => {
		resetErrorBoundary();
	};

	return (
		<StyledLayout>
			<Error500 onButtonClick={handleClick} />
		</StyledLayout>
	);
};

ErrorBoundaryFallback.defaultProps = {
	resetErrorBoundary: () => null,
};

ErrorBoundaryFallback.propTypes = {
	resetErrorBoundary: PropTypes.func,
};

export default ErrorBoundaryFallback;
