import React, { Suspense } from 'react';
import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';
import LoadingScreen from './LoadingScreen';
import isLoadingSelector from '../selectors/isLoading';

const PREFIX = 'SwitcherComp';

const classes = {
	container: `${PREFIX}-container`,
	progress: `${PREFIX}-progress`,
};

const getLocation = ({ location }) => location;

export const SwitcherComp = () => {
	const isLoading = useSelector(isLoadingSelector);
	const location = useSelector(getLocation);
	const { routesMap, type } = location;
	const entry = routesMap[type];
	const { component } = entry;
	const ToRender = React.lazy(component);

	return (
		<Suspense fallback={<LoadingScreen />}>
			<ToRender
				className={cx(
					{ [classes.container]: true },
					{ [classes.progress]: isLoading }
				)}
			/>
		</Suspense>
	);
};

export default SwitcherComp;
