import { getAppList } from '../../context/ClientConfigContext';
import { initialState as userInit } from '../../core/store/User/reducer';
import { initialState as companyInit } from '../../core/store/Company/reducer';
import { initialState as appInit } from '../../core/store/App/reducer';

export const buildReducerEntries = ({ clientConfig } = {}) => {
	const appList = getAppList(clientConfig);
	const reducersResult = {};
	appList.forEach((app) => {
		const { reducers } = app;
		if (typeof reducers === 'function') {
			Object.assign(reducersResult, reducers());
		}
	});
	return reducersResult;
};

const buildConfInitState = (appConfig) => {
	const { reduxInitialisers } = appConfig;
	if (!reduxInitialisers) return {};

	delete reduxInitialisers.user;
	delete reduxInitialisers.company;
	delete reduxInitialisers.app;
	delete reduxInitialisers.apollo;
	return reduxInitialisers;
};

export const buildInitialState = (props = {}) => {
	const { user, clientConfig, i18n, deviseAuth, company, ...rest } = props;

	const confInitState = buildConfInitState(clientConfig);
	let hoistedProfile = {};
	if (user) {
		const { configurationSettings, temporaryAccess, ...other } = user;
		hoistedProfile = {
			userProfile: { ...other },
			temporaryAccess: { ...temporaryAccess },
			configurationSettings: { ...configurationSettings },
		};
	}
	return {
		user: {
			...userInit,
			...hoistedProfile,
		},
		company: { ...companyInit, ...company },
		app: {
			...appInit,
			config: clientConfig,
			serverParams: rest,
			i18n,
		},
		deviseAuth,
		...confInitState,
	};
};
