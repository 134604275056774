import { createAction } from 'redux-actions';

export const constants = {
	goToLogin: 'pages.go_to_login',
	pageWasReloaded: 'pages.page_was_reloaded',
	pageDoneLoaded: 'pages.page_done_loaded',
	goToUnAuthorised: 'pages.go_to_un_authorised',
	goToError401: 'pages.routing.go_to_error401',
	goToError404: 'pages.routing.go_to_error404',
	goToError410: 'pages.routing.go_to_error410',
	goToError500: 'pages.routing.go_to_error500',
	goToTemporaryAccessRequired: 'pages.routing.go_to_temporary_access_required',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
