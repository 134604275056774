import React from 'react';
import PropTypes from 'prop-types';
import connectApp from './ConnectedApp';
import createStore from '../setup/store';
import createApolloClient from '../setup/apollo';
import Switcher from '../../routing/components/Switcher';
import { actions as routingActions } from '../../routing/store/Pages/actions';

const RoutedAppContent = (props) => {
	const { store } = props;
	const state = store.getState();
	const { pages } = state;

	store.dispatch(routingActions.pageWasReloaded(pages));
	return <Switcher />;
};

RoutedAppContent.propTypes = {
	store: PropTypes.objectOf(PropTypes.any).isRequired,
};

const RoutedApp = connectApp(createStore, createApolloClient, RoutedAppContent);

export default RoutedApp;
