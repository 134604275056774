import { createAction } from 'redux-actions';

export const constants = {
	setUserProfile: 'user.set_user_profile',
	setUserMobile: 'user.set_user_mobile',
	clearUser: 'user.clear_user',
	changeUser: 'user.change_user',
	setTemporaryUser: 'user.set_temporary_user',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
