import authReducer from './store/auth/reducer';

export const APP_NAME = 'devise';

export const reducersMap = {
	deviseAuth: authReducer,
};

export default {
	name: APP_NAME,
	reducers: () => reducersMap,
};
